<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Setting"></app-drawer_menu>
     <v-progress-linear
         indeterminate
         color="fourth"
         rounded
         v-if="loading.page"
       ></v-progress-linear>
     <v-card xs3 class="text-xs-center">
     <v-form enctype="multipart/form-data" ref="form_setting" lazy-validation>
	  	<v-tabs
         
	      background-color  ="white"
	      color             ="orange"
	      v-if              = "access.read === 1" 
         @click 	         = "FirstLoad"
	   >
	      <v-tab
            id             = "tabform"
            href   	      ="#tab-form"
         >
            General
         </v-tab>
         <v-tab
            href   	      = "#tab-email"
         >
            Mail
         </v-tab>
         <v-tab
            href   	      = "#tab-password"
         >
            Password
         </v-tab>
         <v-tab
            href   	      = "#tab-absent"
         >
            Absence
         </v-tab>
         <v-tab
            href   	      = "#tab-contact"
         >
            Contact 
         </v-tab>
         <v-tab-item
            value       = "tab-form"
         >
            <v-row class ="mx-2 ma-1">
              <!-- General -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_compname"
                  required 
                  :rules 	  	   ="form.setting_compname_rules"
                  value          ="item.setting_compname"
                  label       	= "Company Name"
                  placeholder 	= "Company Name"
                  id 			   = "setting_compname"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_compaddress"
                  required 
                  :rules 	  	   ="form.setting_compaddress_rules"
                  label       	= "Company Address"
                  placeholder 	= "Company Address"
                  id 			   = "setting_compaddress"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_compphone"
                  required 
                  :rules 	  	   ="form.setting_compphone_rules"
                  label       	= "Company Phone"
                  placeholder 	= "Company Phone"
                  id 			   = "setting_compphone"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_compfax"
                  required 
                  :rules 	  	   ="form.setting_compfax_rules"
                  label       	= "Company Fax"
                  placeholder 	= "Company Fax"
                  id 			   = "setting_compfax"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_plockeddate"
                  required 
                  :rules 	  	   ="form.setting_plockeddate_rules"
                  label       	= "Production Locked Date"
                  placeholder 	= "Production Locked Date"
                  id 			   = "setting_plockeddate"
                  @keyup.enter 	= "Confirm('write','')"
                  v-mask         = "'####-##-##'"
                  background-color = "date"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_flockeddate"
                  required 
                  :rules 	  	   ="form.setting_flockeddate_rules"
                  label       	= "Finance Locked Date"
                  placeholder 	= "Finance Locked Date"
                  id 			   = "setting_flockeddate"
                  @keyup.enter 	= "Confirm('write','')"
                  v-mask         = "'####-##-##'"
                  background-color = "date"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n8">
                  <div class="overline mb-2">Company Logo</div>
                  <v-card xs3 class="text-xs-center cursor" label="Company Logo" flat @click="UploadLogoClick">
                     <v-img 
                           v-model="form.setting_complogo" 
                           :src="form.setting_complogo_url" 
                           contain
                           height="80"
                     ></v-img>
                     <input type="file" ref="setting_complogo" @change="UploadCompLogo" accept="image/*" style="display:none">
                  </v-card>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n8">
                  <div class="overline mb-2">Company Logo External</div>
                  <v-card xs3 class="text-xs-center cursor" flat @click="UploadLogoExtClick">
                      <v-img 
                        v-model="form.setting_complogoext"
                        :src="form.setting_complogoext_url" 
                        contain
                        height="80"
                     ></v-img>
                     <input type="file" ref="setting_complogoext" @change="UploadCompLogoExt" accept="image/*" style="display:none">
                  </v-card>
               </v-col>
               <v-col cols="1" sm="1" md="1">
                  <v-text-field 
                  v-model 	  	   = "form.setting_id"
                  required 
                  :rules 	  	   ="form.setting_id_rules"
                  :readonly      = "form.setting_id_readonly"
                  v-show         = "false"
                  label       	= "ID *"
                  placeholder 	= "ID"
                  id 			   = "setting_id"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
            </v-row>
         </v-tab-item>
         <!-- Password  -->
         <v-tab-item
            value  ="tab-password"
         >
            <v-row class="mx-2 ma-1">
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_password"
                  required 
                  :rules 	  	   ="form.setting_password_rules"
                  label       	= "Default Password"
                  placeholder 	= "Password"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-select
                  v-model 	  	   = "form.setting_pass_level"
                  required 
                  :rules 	  	   ="form.setting_pass_level_rules"
                  :items         ="rows"
                  :item-text     = "rows.text"
                  :item-value    = "rows.value"
                  label       	= "Password Level"
                  placeholder 	= "Password Level"
                  id 			   = "setting_pass_level"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-select>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_pass_signattempt"
                  required 
                  :rules 	  	   ="form.setting_pass_signattempt_rules"
                  label       	= "Sign Attempt"
                  placeholder 	= "Sign Attempt"
                  id 			   = "setting_pass_signattempt"
                  @keyup.enter 	= "Confirm('write','')"
                  reverse
                  background-color = "numeric"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_pass_expday"
                  required 
                  :rules 	  	   ="form.setting_pass_expday_rules"
                  label       	= "Expired Day"
                  placeholder 	= "Expired Day"
                  id 			   = "setting_pass_expday"
                  @keyup.enter 	= "Confirm('write','')"
                  reverse
                  background-color = "numeric"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_pass_minchar"
                  required 
                  :rules 	  	   ="form.setting_pass_minchar_rules"
                  label       	= "Minimal Char"
                  placeholder 	= "Minimal Char"
                  id 			   = "setting_pass_minchar"
                  @keyup.enter 	= "Confirm('write','')"
                  reverse
                  background-color = "numeric"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_pass_maxchar"
                  required 
                  :rules 	  	   ="form.setting_pass_maxchar_rules"
                  label       	= "Max Char"
                  placeholder 	= "Max Char"
                  id 			   = "setting_pass_maxchar"
                  @keyup.enter 	= "Confirm('write','')"
                  reverse
                  background-color = "numeric"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_sessiontimeout"
                  required 
                  :rules 	  	   ="form.setting_sessiontimeout_rules"
                  label       	= "Session Timeout (Minutes)"
                  placeholder 	= "Session Timeout (Minutes)"
                  id 			   = "setting_sessiontimeout"
                  @keyup.enter 	= "Confirm('write','')"
                  reverse
                  background-color = "numeric"
                  >
                  </v-text-field>
               </v-col>
               <!-- LDAP -->
               <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <label class="caption">LDAP Status</label>
                  <v-switch
                     v-model	="form.setting_ldap"
                     color  	= "primary"
                     id 		= "form.setting_ldap"
                     :label 	="form.setting_ldap !== false ? 'Active' : ''" 
                  ></v-switch>
				   </v-col>
               <v-col cols="8" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_ldap_server"
                  required 
                  :rules 	  	   ="form.setting_ldap_server_rules"
                  v-if           ="form.setting_ldap !== false"
                  label       	= "LDAP Server"
                  placeholder 	= "IP"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
            </v-row>
         </v-tab-item>
         <!-- Email -->
         <v-tab-item
            value  ="tab-email"
         >
            <v-row class="mx-2 ma-1">
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_smtp_server"
                  required 
                  :rules 	  	   ="form.setting_smtp_server_rules"
                  label       	= "SMTP Server"
                  placeholder 	= "SMTP Server"
                  id 			   = "setting_smtp_server"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="6" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_smtp_port"
                  required 
                  :rules 	  	   ="form.setting_smtp_port_rules"
                  label       	= "SMTP Port"
                  placeholder 	= "SMTP Port"
                  id 			   = "setting_smtp_port"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_imap_server"
                  required 
                  :rules 	  	   ="form.setting_imap_server_rules"
                  label       	= "POP3/IMAP Server"
                  placeholder 	= "IP"
                  id 			   = "setting_imap_server"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="6" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_imap_port"
                  required 
                  :rules 	  	   ="form.setting_imap_port_rules"
                  label       	= "POP3/IMAP Port"
                  placeholder 	= "Port"
                  id 			   = "setting_imap_port"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_smtp_email"
                  required 
                  :rules 	  	   ="form.setting_smtp_email_rules"
                  label       	= "Admin Email"
                  placeholder 	= "SMTP Email"
                  id 			   = "setting_smtp_email"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_smtp_password"
                  required 
                  :rules 	  	   ="form.setting_smtp_password_rules"
                  :append-icon   ="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  label       	= "Admin Password"
                  :type          ="show1 ? 'text' : 'password'"
                  placeholder 	= "Pass"
                  id 			   = "setting_smtp_password"
                  @click:append  ="show1 = !show1"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	   = "form.setting_email_domain"
                  required 
                  :rules 	  	   ="form.setting_email_domain_rules"
                  label       	= "Email Domain"
                  placeholder 	= "Domain"
                  id 			   = "setting_email_domain"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
            </v-row>
         </v-tab-item>
         <!-- Time Absent -->
         <v-tab-item
            value  ="tab-absent"
         >
            <v-row class="mx-2 ma-1">
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_timein"
                  required 
                  :rules 	  	   ="form.setting_timein_rules"
                  label       	= "Time In"
                  placeholder 	= "Insert time"
                  id 			   = "setting_timein"
                  v-mask         = "'##:##:##'"
                  @keyup.enter 	= "Confirm('write','')"
                  background-color = "date"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_timeout"
                  required 
                  :rules 	  	   ="form.setting_timeout_rules"
                  label       	= "Time Out"
                  placeholder 	= "Insert Time"
                  id 			   = "setting_timeout"
                  v-mask         = "'##:##:##'"
                  @keyup.enter 	= "Confirm('write','')"
                  background-color = "date"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_timeinlate"
                  required 
                  :rules 	  	   ="form.setting_timeinlate_rules"
                  label       	= "Max Time In"
                  placeholder 	= "Insert Time"
                  id 			   = "setting_timeinlate"
                  v-mask         = "'##:##:##'"
                  @keyup.enter 	= "Confirm('write','')"
                  background-color = "date"
                  >
                  </v-text-field>
               </v-col>
               <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n4">
                  <label class="caption">Using Geolocation?</label>
                  <v-switch
                     v-model	="form.setting_geolocation"
                     color  	= "primary"
                     id 		= "form.setting_geolocation"
                     :label 	="form.setting_geolocation !== true ? '' : 'Active'" 
                  ></v-switch>
				   </v-col>
               <!-- Active -->
               <v-col cols="12" sm="2" md="2" class="mt-n4">
                  <label class="caption">Using Camera?</label>
                  <v-switch
                     v-model	="form.setting_cameraselfie"
                     color  	= "primary"
                     id 		= "form.setting_cameraselfie"
                     :label 	="form.setting_cameraselfie !== true ? '' : 'Active'" 
                  ></v-switch>
				   </v-col>
               <v-col cols="12" sm="2" md="2">
                  <v-text-field 
                  v-model 	  	   = "form.setting_absenceradius"
                  required 
                  :rules 	  	   ="form.setting_absenceradius_rules"
                  label       	= "Absence Radius in Metres"
                  placeholder 	= "Absence Radius in Metres"
                  id 			   = "setting_absenceradius"
                  @keyup.enter 	= "Confirm('write','')"
                  background-color = "numeric"
                  reverse
                  >
                  </v-text-field>
               </v-col>
            </v-row>
         </v-tab-item>
         <!-- Contact -->
         <v-tab-item
            value  ="tab-contact"
         >
            <v-row class="mx-2 ma-1">
               <v-col cols="12" sm="6" md="6">
                  <v-text-field 
                  v-model 	  	   = "form.setting_ccarewhatsapp"
                  required 
                  :rules 	  	   ="form.setting_ccarewhatsapp_rules"
                  label       	= "Customer Care Whatsapp Contact"
                  placeholder 	= "No"
                  id 			   = "setting_ccarewhatsapp"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="6" md="6">
                  <v-text-field 
                  v-model 	  	   = "form.setting_claimwhatsapp"
                  required 
                  :rules 	  	   ="form.setting_claimwhatsapp_rules"
                  label       	= "Claim Whatsapp Contact"
                  placeholder 	= "No"
                  id 			   = "setting_claimwhatsapp"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="6" md="6">
                  <v-text-field 
                  v-model 	  	   = "form.setting_ccareemail"
                  required 
                  :rules 	  	   ="form.setting_ccareemail_rules"
                  label       	= "Customer Care Email Contact"
                  placeholder 	= "Mail"
                  id 			   = "setting_ccareemail"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="6" md="6">
                  <v-text-field 
                  v-model 	  	   = "form.setting_claimemail"
                  required 
                  :rules 	   	="form.setting_claimemail_rules"
                  label       	= "Claim Email Contact"
                  placeholder 	= "Mail"
                  id 			   = "setting_claimemail"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
            </v-row>
         </v-tab-item>
      </v-tabs>
      <v-row class="mx-2 ma-1">
      <v-col cols="12" sm="12" md="12" >
         <v-card-actions>
            <v-btn
            color		         ="primary"
            class		         ="float-right"
            :disabled         = "access.add === 1 || access.edit === 1 ? false:true"
            rounded
            block
            @click 	         = "Confirm('write','')"
            >
               <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
            </v-btn>
         </v-card-actions>
      </v-col>
      </v-row>
      <v-row class="mx-2">
      <v-col cols="12" sm="5" md="5">
      <div v-show="url_type !== 'add' ? true:false ">
            <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
         <v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.setting_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.setting_cuser)">{{form.setting_cuser}}</span>
Last Update Date : {{form.setting_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.setting_luser)">{{form.setting_luser}}</span>
</pre>
         </v-banner>
      </div>
      </v-col>
      </v-row>
   </v-form>
</v-card>
<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
         <v-toolbar dark color="fourth" dense>
         <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
         </v-btn>
         <v-toolbar-title>Error Messages</v-toolbar-title>
         </v-toolbar>
         <v-card-text class="mt-2 ml-n2">
           <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
         </v-card-text>
      </v-card>
   </v-dialog>
	<v-snackbar
     v-model   = "snackbar.dialog"
      :timeout = "snackbar.timeout"
      color    = "fourth"
      rounded  = "pill"
      top
      style    = "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
     </template>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
          <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
          <v-card-text>
              
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="dialog" :color="Variable('confirm',1)"
                  :disabled   = "confirm.text === 'Ok' ? false:true"
                @click      = "Loading">{{confirm.text}}</v-btn>
              <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
   <!-- Dialog Users -->
   <app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
  	data: () => ({
      url_type: 'edit',
      cont: false,
      show1:false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
  		},
  		snackbar: {
         dialog: false,
         color: 'success',
         text: '',
         timeout: 3000
      },
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	text: 'Ok',
	    	disabled: false,
	    },
       remarks: {
         feedback:'',
         dialog: false,
       },
        singleselect: false,
        selected: [],
        search: '',
        rows: [
            {text: 'High', value: 'H'},
            {text: 'Low', value: 'L'},
        ],
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'setting_id',
          },
         //  { text: 'Name',               value: 'setting_compname' },
        ],
        items: [],
        loading: {
          page: true,
          list : false
        },
        btn_search: true,
        form: {
        	setting_id: '',
        	setting_id_rules: [v => !!v || 'Please fill ID'],
         setting_id_readonly: true,
        	setting_compname: '',
         setting_compname_rules: [v => !!v || 'Please fill Company Name'],
         setting_compaddress: '',
         setting_compaddress_rules: [v => !!v || 'Please fill Company Address'],
         setting_compphone: '',
         setting_compphone_rules: [v => !!v || 'Please fill Company Phone'],
         setting_compfax: '',
         setting_compfax_rules: [v => !!v || 'Please fill Company Fax'],
         setting_plockeddate: '',
         setting_plockeddate_rules: [v => !!v || 'Please fill Company Production Locked Date'],
         setting_flockeddate: '',
         setting_flockeddate_rules: [v => !!v || 'Please fill Company Finance Locked Date'],
         setting_password: '',
         setting_password_rules: [v => !!v || 'Please fill the Default Password'],
         setting_pass_level: '',
         setting_pass_level_rules: [v => !!v || 'Please Choose the Level Password'],
         setting_pass_signattempt : '',
         setting_pass_signattempt_rules: [v => !! v || 'Please fill Password Sign Attempt'],
         setting_pass_expday: '',
         setting_pass_expday_rules: [v => !!v || 'Please fill password Expired Day'],
         setting_pass_minchar: '',
         setting_pass_minchar_rules: [v => !!v || 'Please fill Minimal Character'],
         setting_pass_maxchar: '',
         setting_pass_maxchar_rules: [v => !!v || 'Please fill Maximal Character'],
         setting_sessiontimeout: '',
         setting_sessiontimeout_rules: [v => !!v || 'Please fill Session Timeout'],
         setting_ldap: true,
         setting_geolocation:true,
         setting_ldap_server: '',
         setting_ldap_server_rules: [v => !!v || 'Please fill LDAP Server'],
         setting_smtp_server: '',
         setting_smtp_server_rules: [v => !!v || 'Please fill SMTP Server'],
         setting_smtp_port: '',
         setting_smtp_port_rules: [v => !!v || 'Please fill SMTP Port'],
         setting_imap_server: '',
         setting_imap_server_rules: [v => !!v || 'Please fill IMAP Server'],
         setting_imap_port: '',
         setting_imap_port_rules: [v => !!v || 'Please fill IMAP Port'],
         setting_smtp_email: '',
         setting_smtp_email_rules: [v => !!v || 'Please fill Admin Email'],
         setting_smtp_password: '',
         setting_smtp_password_rules: [v => !!v || 'Please fill Admin Password'],
         setting_email_domain: '',
         setting_email_domain_rules: [v => !!v || 'Please fill Email Domain'],
         setting_timein: '',
         setting_timein_rules: [v => !!v || 'Please fill Company Time In'],
         setting_timeout: '',
         setting_timeout_rules: [v => !!v || 'Please fill Company Time Out'],
         setting_timeinlate: '',
         setting_timeinlate_rules: [v => !!v || 'Please fill Company Time In Late'],
         setting_ccarewhatsapp: '',
         setting_ccarewhatsapp_rules: [v => !!v || 'Please fill Company Time In Late'],
         setting_claimwhatsapp: '',
         setting_claimwhatsapp_rules: [v => !!v || 'Please fill Company Claim Whatsapp Contact'],
         setting_ccareemail: '',
         setting_ccareemail_rules: [v => !!v || 'Please fill Company Customer Care Email Contact'],
         setting_claimemail: '',
         setting_claimemail_rules: [v => !!v || 'Please fill Company Claim Email Contact'],
         setting_password_hint: false,
         setting_complogo: '',
         setting_complogo_url:'',
         setting_complogo_old:'',
         setting_complogoext: '',
        	setting_complogoext_url:'',
         setting_complogoext_old:'',
         setting_absenceradius:0,
         setting_absenceradius_rules: [v => !!v || 'Please fill Absence Radius'],
         setting_cameraselfie: '',
         display_users: '',
         },
         dialog: {
            users: false
         },
      }),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
        //this function use for validating the screen with user privilleges
        this.Access()
        //this function use for call data from db
        this.Show()
      },
      //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
         let modul = 'setting'
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = response.data
                if (priv.length > 0) {
                 if (priv[0].feedback !== 'Y') {
                   this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                   this.remarks.dialog = true
                   this.remarks.feedback = priv[0].feedback
                 } else {
                   this.access.read    = priv[0].usersmenu_read
                   this.access.add     = priv[0].usersmenu_add
                   this.access.edit    = priv[0].usersmenu_edit
                   this.access.delete  = priv[0].usersmenu_delete
                   if (priv[0].usersmenu_read === 0) {
                     this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                   }
                 }
             } else {
                 this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
             }
             this.loading.page = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
            this.loading.page = false
			})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
	   Confirm (flag) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_setting.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
           }
        },
		//Function to request update data to table setting 'The API will validating your data, include the user who process the 
      Show () {
			let formdata = {
					setting_id: '',
					order_by: 'setting_id',
					order_type: 'ASC',
					limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListSetting'), param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
               if (feedback[0].feedback === 'Y') {
            		// document.getElementById('tab-form').click()
                  this.form.setting_id   	               = feedback[0].setting_id
                  this.form.setting_compname             = feedback[0].setting_compname
                  this.form.setting_compaddress          = feedback[0].setting_compaddress
                  this.form.setting_compphone            = feedback[0].setting_compphone
                  this.form.setting_compfax              = feedback[0].setting_compfax
                  this.form.setting_plockeddate          = feedback[0].setting_plockeddate
                  this.form.setting_flockeddate          = feedback[0].setting_flockeddate
                  this.form.setting_password             = feedback[0].setting_password
                  this.form.setting_pass_level           = this.$functions.Level(feedback[0].setting_pass_level)
                  this.form.setting_pass_signattempt     = feedback[0].setting_pass_signattempt
                  this.form.setting_pass_expday          = feedback[0].setting_pass_expday
                  this.form.setting_pass_minchar         = feedback[0].setting_pass_minchar
                  this.form.setting_pass_maxchar         = feedback[0].setting_pass_maxchar
                  this.form.setting_smtp_server          = feedback[0].setting_smtp_server
                  this.form.setting_smtp_port            = feedback[0].setting_smtp_port
                  this.form.setting_smtp_email           = feedback[0].setting_smtp_email
                  this.form.setting_complogo             = feedback[0].setting_complogo
                  this.form.setting_complogo_old         = feedback[0].setting_complogo
                  this.form.setting_complogo_url         = feedback[0].setting_complogo_url
                  this.form.setting_complogoext          = feedback[0].setting_complogoext
                  this.form.setting_complogoext_old      = feedback[0].setting_complogoext
                  this.form.setting_complogoext_url      = feedback[0].setting_complogoext_url
                  this.form.setting_sessiontimeout       = feedback[0].setting_sessiontimeout
                  this.form.setting_ldap                 = feedback[0].setting_ldap
                  this.form.setting_ldap_server          = feedback[0].setting_ldap_server
                  this.form.setting_email_domain         = feedback[0].setting_email_domain
                  this.form.setting_imap_server          = feedback[0].setting_imap_server
                  this.form.setting_imap_port            = feedback[0].setting_imap_port
                  this.form.setting_smtp_password        = feedback[0].setting_smtp_password
                  this.form.setting_timein               = feedback[0].setting_timein
                  this.form.setting_timeout              = feedback[0].setting_timeout
                  this.form.setting_timeinlate           = feedback[0].setting_timeinlate
                  this.form.setting_premijkk             = feedback[0].setting_premijkk
                  this.form.setting_premijk              = feedback[0].setting_premijk
                  this.form.setting_iuranjht             = feedback[0].setting_iuranjht
                  this.form.setting_ccarewhatsapp        = feedback[0].setting_ccarewhatsapp
                  this.form.setting_claimwhatsapp        = feedback[0].setting_claimwhatsapp
                  this.form.setting_ccareemail           = feedback[0].setting_ccareemail
                  this.form.setting_claimemail           = feedback[0].setting_claimemail
                  this.form.setting_absenceradius        = feedback[0].setting_absenceradius
                  this.form.setting_geolocation          = this.$functions.TrueOrFalse(feedback[0].setting_geolocation)
                  this.form.setting_cameraselfie         = this.$functions.TrueOrFalse(feedback[0].setting_cameraselfie)
                  // this.form.setting_actived           = this.$functions.TrueOrFalse(response.data[0].setting_actived)
                  this.form.setting_cdate                = feedback[0].setting_cdate
                  this.form.setting_cuser                = feedback[0].setting_cuser
                  this.form.setting_ldate                = feedback[0].setting_ldate
                  this.form.setting_luser                = feedback[0].setting_luser
                  this.form.setting_id_readonly          = true
                  setTimeout(function(){
                     document.getElementById('setting_compname').focus()
                  }, 500)
               } else {
                  this.SnackBar(true, 'error', feedback[0].feedback, 0)
   	  			}
            }
			})
			.catch(e => {
            this.SnackBar(true, 'error', e, 0)
			})
		},
	  	Loading () {
	  		this.confirm.text 	 = 'Loading...'
	  		if (this.url_type === 'edit') {
	  			this.Write()
	  		}
	  	},
      Write () {
         let formdata = {
            url_type: this.url_type,
            setting_id: this.form.setting_id,
            setting_compname: this.form.setting_compname,
            setting_compaddress: this.form.setting_compaddress,
            setting_compphone:this.form.setting_compphone,
            setting_compfax: this.form.setting_compfax,
            setting_plockeddate: this.form.setting_plockeddate,
            setting_flockeddate: this.form.setting_flockeddate,
            setting_password: this.form.setting_password,
            setting_pass_level: this.form.setting_pass_level,
            setting_pass_signattempt: this.form.setting_pass_signattempt,
            setting_pass_expday: this.form.setting_pass_expday,
            setting_pass_minchar: this.form.setting_pass_minchar,
            setting_pass_maxchar: this.form.setting_pass_maxchar,
            setting_smtp_server: this.form.setting_smtp_server,
            setting_smtp_port: this.form.setting_smtp_port,
            setting_smtp_email: this.form.setting_smtp_email,
            setting_sessiontimeout: this.form.setting_sessiontimeout,
            setting_ldap: String(this.form.setting_ldap),
            setting_ldap_server: this.form.setting_ldap_server,
            setting_email_domain: this.form.setting_email_domain,
            setting_imap_server: this.form.setting_imap_server,
            setting_imap_port: this.form.setting_imap_port,
            setting_smtp_password: this.form.setting_smtp_password,
            setting_timein: this.form.setting_timein,
            setting_timeout: this.form.setting_timeout,
            setting_timeinlate: this.form.setting_timeinlate,
            setting_premijkk: this.form.setting_premijkk,
            setting_premijk: this.form.setting_premijk,
            setting_iuranjht: this.form.setting_iuranjht,
            setting_ccarewhatsapp: this.form.setting_ccarewhatsapp,
            setting_claimwhatsapp: this.form.setting_claimwhatsapp,
            setting_ccareemail: this.form.setting_ccareemail,
            setting_claimemail: this.form.setting_claimemail,
            setting_absenceradius: this.form.setting_absenceradius,
            setting_geolocation: this.$functions.SwitchFlag(this.form.setting_geolocation),
            setting_cameraselfie: this.$functions.SwitchFlag(this.form.setting_cameraselfie),
         }
         let param      = this.$functions.ParamPOST(formdata)
         this.$axios.post(this.$functions.UrlPOST('apiWriteSetting'),param,{
           headers: {
               'Content-Type': 'text/plain; charset=ISO-8859-1'
           }
         })
         .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
               if (feedback[0].feedback === 'Y') {
                  this.Upload()
               } else {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog = true
                  this.remarks.feedback = feedback[0].feedback
               }
            } else {
               this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
               this.remarks.dialog = true
               this.remarks.feedback = feedback
            }
         })
         .catch(e => {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = e
         })
      },
      //Function for give preview image Company Logo
      UploadLogoClick () {
         this.$refs.setting_complogo.click()
      },
      UploadCompLogo () {
         // Reference to the DOM input element
         var input = event.target
         // Ensure that you have a file before attempting to read it
         if (input.files && input.files[0]) {
         // create a new FileReader to read this image and convert to base64
         var reader = new FileReader()
         // Define a callback function to run, when FileReader finishes its job
         reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
               this.form.setting_complogo_url = e.target.result
         }
         this.form.setting_complogo = input.files[0]
         // Start the reader job - read file as a data url (base64 format)
         reader.readAsDataURL(input.files[0])
         }
      },
      Upload () {
        	// Append to FormData, because JWT not support form multipart/form-data to upload files
            let formData  = new FormData()
            formData.append('url_type', 'setting')
            formData.append('setting_id', this.form.setting_id)
            formData.append('setting_complogo_old', this.form.setting_complogo_old)
            formData.append('setting_complogo', this.form.setting_complogo)
            formData.append('setting_complogoext_old', this.form.setting_complogoext_old)
            formData.append('setting_complogoext', this.form.setting_complogoext)
            this.$axios.post(this.$functions.UploadPOST('apiWriteUploadFile', ``), formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
            })
            .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
   				if (feedback[0].feedback === 'Y') {
   	        		this.SnackBar(true, 'success', 'Save Success', 3000)
                  this.confirm.dialog      = false
                  this.confirm.text        = 'Ok'
                  if (this.url_type === 'add') {
                     this.form.setting_cuser  = feedback[0].feedback_users_id
                     this.form.setting_cdate  = feedback[0].feedback_users_date
                  }
                  this.form.setting_luser  = feedback[0].feedback_users_id
                  this.form.setting_ldate  = feedback[0].feedback_users_date
                  this.url_type         = 'edit'
   	  			} else {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
   					this.remarks.dialog = true
   					this.remarks.feedback = feedback[0].feedback
   				}
            } else {
               this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
               this.remarks.dialog = true
               this.remarks.feedback = feedback[0].feedback
            }
			})
        	.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
      //Function for give preview image company logo external
      UploadLogoExtClick () {
         this.$refs.setting_complogoext.click()
      },
      UploadCompLogoExt () {
         // Reference to the DOM input element
         var input = event.target
         // Ensure that you have a file before attempting to read it
         if (input.files && input.files[0]) {
         // create a new FileReader to read this image and convert to base64
         var reader = new FileReader()
         // Define a callback function to run, when FileReader finishes its job
         reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
               this.form.setting_complogoext_url = e.target.result
         }
         this.form.setting_complogoext = input.files[0]
         // Start the reader job - read file as a data url (base64 format)
         reader.readAsDataURL(input.files[0])
         }
      },
	  	SnackBar (dialog, color, text, timeout) {
         this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout
         }
         if (color === 'error') {
            this.confirm.dialog   = false
            this.confirm.text     = 'Ok'
         }
      },
      ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	}
}
</script>